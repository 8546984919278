import './Main_page.css';
import { Link } from "react-router-dom";

export default function Main_page() {
  return (
      <div className="Main_page_main_div">
        <h3>Quidquid latine dictum sit, altum sonatur
        </h3>
        <p>
          And remember: You have no obligations here.
          <br /><br />
          Life is short. Make sure you spend as much time as you can, arguing with a complete stranger on the internet about things you don't agree with and don’t care about.
        </p>
        <h6>****</h6>
        <h1 id='main_page_h1'>Meet Pavel </h1>
        <h3 id='main_page_h3'>41 years old, from Bulgaria with some traditional values</h3>
        <div>
          <img src="img/Pavel-12.jpg" alt="pavel_img" />
        </div>

       
       

        <h3>ABOUT ME</h3>
        <p>First, I want to point out the most important thing – I am... somewhat intelligent.
          Different level of perception and communication.
        </p>
        <p>Forget everything you know and think you know about men and people. That’s not me. Open a new blank page, free your mind and get to know me.
          <br />*<br />
          I’m DTF – Down To Finally be in a relationship with someone who actually cares about us, me, our mental health and we support each other.
          <br />*<br />
          I took some selfies for this profile, but then I saw them, I realized that personality is more important.
          <br />*<br />
          Curiosity is one of my main characteristics, leader, social, happy. Equity, integrity and fairness are big things for me. I lead a modest, calm lifestyle. There is natural toxicity in me.
          <br /><br />
          I don’t smoke or drink, cheat, abuse, gamble, use drugs. I prefer to suffer in this life sober.
          <br />Strong mentally and physically, not greedy or possessive, I like intimacy, cuddling. Very practical Minimalist, down to earth, realist, genuine, friendly, good person, authentic, relaxed, honest, trustworthy, reliable, sarcastic, wholesome, peaceful, slow to anger, fast to laugh, home person, extroverted introvert. A decent, no-baggage guy. The type of guy you bring home, show to your friends and family, snug on the couch, eat pizza and watch a movie.
          <br />Being myself without pretending or do things only so people could like me, don’t care what people think, but do care for my friends, family and environment.
          <br />Family and friends are most important to me. I’ve learned that money come and go, but your friends and family don't.
          <br />Grateful for the things I have in life.
          <br />Open-minded (lot of people think this means agreeable).
          <br />Peaceful (lot of people think that this means harmless).
          <br /><br />
          I hope one day I can pass down those qualities to a family of my own. I have goodness in me and I look for that woman to share with, but I’ve been disappointed in the past and now I’m careful. Furthermore, I don’t take people for good by default. They need to prove themselves.
          <br /><br />
          I was raised by a single mother. Watched her get hurt many times and make mistakes, from which I learned a lot.
          <br />Made me understand how important it is to treat a woman right, to support her and be there for her. My life also taught me to have inner strength and independence. It made me understand just how fucked up society is.
          <br />I’ve had to overcome shitty life and that made me a stronger person.
          <br /><br />
          Hobbies: Comedy, memes, cycling, video games, anime, reading, psychology, philosophy, science, technology, DIY.
          <br />And as every human – Music, movies, traveling, parks, nature, seeks like-minded.
          <br /><br />
          I’m also a handyman, I have knowledge about buildings, construction, mechanics and electronics. Can’t fix everything, but I’ll try.
          <br />A dream of mine is to build my own house someday, but also to try van-life as well.
          <br /><br /> I prefer to be single, but not with the wrong person.<br /><br />
          Cancer – Ambivert – Quirkyalone – Chaotic Good – above average IQ – INTP/ESTJ/ENTJ – if these things matter for you.</p>
        <h6>****</h6>

        <h3>WHAT I LOOK FOR</h3>
        <p>Need a girl who likes to cycle ( I don’t own a car).
          <br />*<br />
          Ready to date, supportive, available, feminine, thinking about KIDS and FAMILY, decent human, fully taking responsibility for herself, curious about life, down to earth, at least some sense of humor, honest, real warm-hearted and open-minded, who invest in the relationship as much as I do, a real partner in life. She must let me steal. I love stealing…. hugs and but grabs.
          <br />I want a meaningful relationship in which we grow old and fat together, need a woman to work with me for a better future for us, looking for a woman with personality.
          <br /><br />
          Ideally, looking for that woman to fit in my life completely and make my existence whole, my life accomplished and make me happy taken.
          <br />I have control over my shit and I am comfortable, to win me over your presence need to feel good. A woman I can vibe with on a spiritual, physical and intellectual level. Someone who inspires me to be a better person.
          <br />I’d like to find that beautiful type of relationship where life can be shared on a deeper level.
          <br />*<br />
          A big plus is if you recognize your dating/relationships/mental problems and take messures about it.</p>
        <h6>****</h6>

        <h3>DEAL BREAKERS</h3>
        <p>Not single, with kids, heavy makeup, Daddy issues, Unhealthy, Smokers, FAT, Vegans, Selfish, Narcissistic, Woke, Religious, Mind gamers, Drama queens, Pretentious, Princesses, Queens, Have the body count of a dog, Prostitutes, Materialists, Gold diggers, OnlyFans and LGBT+++
          <br />
          <br />- The ”perfect” woman. Perfect people do not exist.
          <br />- The women looking for a man to fix their broken lives and all they offer is an overused pussy.
          <br />- Your happiness Is not my responsibility, you and I should be happy as an individual, then we can come together to share our happiness.
          <br />- Love is a long process, if you are looking for a quick thrill and compliments, you bark at the wrong tree.
          <br />-  The most I like in a girl is her personality, but don’t call me if you have several. I really can’t stress enough that: if you’re a broken woman, the unbroken men don't want you.
          <br />- If having an animal is more important than having a man, you are not ready for a relationship.
          <br />- The thing I really hate is the overthought wrongly understood false accusations.
          <br />Taking something I said, imagine something ludicrous and passing that crazy shit as my words.
          <br />Example: I said – I don’t have a pet. Some women translated this to: 'I hate animals'.
          <br />What I meant is: 'I don’t have a pet, because most of the animals need social interactions'.
          <br />Getting an animal and locking it at home is NOT good or moral,it is a torture.  it’s also illegal in some countries.
          <br />-  “I’m a strong independent woman, I don’t clean and cook”. Cooking and cleaning are not a gender role, they are a basic grown up human skills.
          <br />- Sooner or later, everyone finds the right person. To some of you, this is the psychiatrist.
          <br />- Remember when you found out there was no Santa? Well, now, let me tell you about the prince on a white horse ….</p>
        <h6>****</h6>
        <h5>If you survived all this and you want to talk to the man, click the button bellow. </h5>
        <Link to='/contact_page' ><button>Contact form</button></Link>

 {/* Copyright */}
 <br/>
 <br/>
 <footer>
 <div>
      © 2021 Copyright:{" "}
      <a
        href="https://rocnogu.com"
        rel="noreferrer noopener"
        target="_blank"
        style={{ color: "red" }}
      >
                rocnogu and SOFU Projects

      </a>
    </div>
  </footer>
      </div>
      
  );
}


