import Contact_page from './contact_page/Contact_page'
import About_page from './about_page/About_page'
import Main_page from './main_page/Main_page'
import No_page from './no_page/No_page'
import Terms from './terms/Terms'
////////////////
import { BrowserRouter, Routes, Route } from "react-router-dom";
////////////////

export default function App() {
  ////////////////
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route path="/terms_and_conditions" element={<Terms />} />
          <Route path="/contact_page" element={<Contact_page />} />
          <Route path="/Main_page" element={<Main_page />} />
          <Route path="/" element={<About_page />} />
          <Route path="*" element={<No_page />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}
