import './No_page.css';
import { Link } from "react-router-dom";

function No_page() {
  return (
    <div className="No_page">
      <div className="No_page_main_div">
        <h1>Error 404</h1>
        <h1>
          There is no such page.
        </h1>

      </div>
      <h5>If you want to send a bug report, you can do it through the contact form. </h5>
        <Link to='/contact_page' ><button>Contact form</button></Link>
<br/>
<div><p>Or go back to the start page</p></div>
        
        <div id='contact_back_link'><Link to='/'  ><button>Go back to the start page</button></Link></div>

 {/* Copyright */}
 <br/>
 <br/>
 <footer>
 <div>
      © 2021 Copyright:{" "}
      <a
        href="https://rocnogu.com"
        rel="noreferrer noopener"
        target="_blank"
        style={{ color: "red" }}
      >
                rocnogu and SOFU Projects

      </a>
    </div>
  </footer>
    </div>
  );
}

export default No_page;
